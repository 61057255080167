import React, { useState, useEffect } from 'react';
import DefaultLayout from '../../layouts/DefaultLayout';
import TemplateColumn from '../../layouts/TemplateColumn';
import { PageHeader, PageIntro, PageSection, PageSubSection, AnchorLinks, RelatedContent, IconDefinitionList, TextBlock, ResourceLibrary, PrimaryCallOut, Video, ReferencesBlock } from '../../components';
import { StaticImage } from 'gatsby-plugin-image';
import { jobCodes } from '../../util/data/jobCodes';

// SEO INFORMATION
const pageMeta = {
    title: `Surgeon Resources for KEYTRUDA® (pembrolizumab) | HCP`,
    keywords: `keytruda surgeon resources`,
    description: `Explore downloadable resources for KEYTRUDA® (pembrolizumab) including a video, designed to be used by surgeons when developing treatment plans for patients.`,
    schemaJsonLD: [
        '{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/resources/surgeon-resources/","@type":"VideoObject","name":"RCC: Which Patients May Be Eligible for Adjuvant Treatment?","description":"Learn about risk of recurrence data for certain patients with RCC post-surgery, and which patients may be eligible to receive KEYTRUDA in the adjuvant setting after nephrectomy.","duration":"11M12S","thumbnailURL":"https://cf-images.us-east-1.prod.boltdns.net/v1/static/1905768940001/f7acbe28-ed3b-4791-9450-9268e249fc5f/83630853-a452-4508-9df9-9e39ece78e82/1280x720/match/image.jpg","embedURL":"https://players.brightcove.net/1905768940001/meAxH3mZK_default/index.html?videoId=6343505462112"}',
        '{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/resources/surgeon-resources/","@type":"MedicalWebPage","name":"Resources for Surgeons","description":"As a surgeon, you play a critical role in developing comprehensive treatment plans for patients. The resources below can help you collaborate with your patient’s medical oncologist and multidisciplinary team (MDT) to determine if your patient is eligible for neoadjuvant and adjuvant therapy or adjuvant therapy only, together with surgery."}',
        '{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/resources/surgeon-resources/","@type":"MedicalWebPage","name":"Surgeon Resource Center","description":"As Part of a Treatment Regimen for Certain Cancers, Consider KEYTRUDA® (pembrolizumab) Together With Surgery in the Neoadjuvant and Adjuvant Settings or Adjuvant Setting"}',
        '{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/resources/surgeon-resources/","@type":"MedicalAudience","audienceType":"health care professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico. "}'
    ]
}

const jobCode = jobCodes[31].jobCode;

const relatedContent = [
    {
        section: 'Resources',
        link: {
            url: '/efficacy/',
            label: 'Efficacy'
        }
    },
    {
        section: 'Resources',
        link: {
            url: '/safety/adverse-reactions/',
            label: 'Safety'
        }
    },
    {
        section: 'Resources',
        link: {
            url: '/resources/mechanism-of-action/',
            label: 'Mechanism of Action'
        }
    }
];

const anchorLinkModelData = [
    { label: 'Earlier-Stage Indications' }, 
    { label: 'Resources for Surgeons' }, 
    { label: 'Video Resource' }, 
]

const selectedEarlierStageIndicationData = [
    {
        iconColumn: {
            icon: '/static/images/badge-NSCLC.svg',
            heading: 'NSCLC',
            alt: ''
        },
        contentMarkup: `
            <p>KEYTRUDA is indicated for the treatment of patients with resectable (tumors ≥4 cm or node positive) non–small cell lung cancer (NSCLC) in combination with platinum⁠-⁠containing chemotherapy as neoadjuvant treatment, and then continued as a single agent as adjuvant treatment after surgery.</p>
            <p>KEYTRUDA, as a single agent, is indicated for adjuvant treatment following resection and platinum-based chemotherapy for adult patients with stage IB (T2a ≥4 cm), II, or IIIA non–small cell lung cancer (NSCLC).</p>
            <p><strong>Phase III Studies<sup>1,2</sup></strong></p>
            <ul>
                <li>KEYNOTE&#8288;-&#8288;671 (neoadjuvant and adjuvant)</li>
                <li>KEYNOTE&#8288;-&#8288;091 (adjuvant)</li>
            </ul>
        `,
    },
    {
        iconColumn: {
            icon: '/static/images/badge-TNBC.svg',
            heading: 'TNBC',
            alt: ''
        },
        contentMarkup: `
            <p>KEYTRUDA is indicated for the treatment of patients with high-risk early-stage triple-negative breast cancer (TNBC) in combination with chemotherapy as neoadjuvant treatment, and then continued as a single agent as adjuvant treatment after surgery.</p>
            <p><strong>Phase III Studies<sup>3</sup></strong></p>
            <ul>
                <li>KEYNOTE&#8288;-&#8288;522 (neoadjuvant and adjuvant)</li>
            </ul>
        `,
    },
    {
        iconColumn: {
            icon: '/static/images/badge-RCC.svg',
            heading: 'RCC',
            alt: ''
        },
        contentMarkup: `
            <p>KEYTRUDA is indicated for the adjuvant treatment of patients with renal cell carcinoma (RCC) at intermediate-high or high risk of recurrence following nephrectomy, or following nephrectomy and resection of metastatic lesions.</p>
            <p><strong>Phase III Studies<sup>4</sup></strong></p>
            <ul>
                <li>KEYNOTE&#8288;-&#8288;564 (adjuvant)</li>
            </ul>
        `,
    },
    {
        iconColumn: {
            icon: '/static/images/badge-MEL.svg',
            heading: 'MELANOMA',
            alt: ''
        },
        contentMarkup: `
            <p>KEYTRUDA is indicated for the adjuvant treatment of adult and pediatric (12 years and older) patients with stage IIB, IIC, or Ill melanoma following complete resection.</p>
            <p><strong>Phase III Studies<sup>5,6</sup></strong></p>
            <ul>
                <li>KEYNOTE&#8288;-&#8288;716 (adjuvant)</li>
                <li>KEYNOTE&#8288;-&#8288;054 (adjuvant)</li>
            </ul>
                       `,
    },
]

const resourceLibraryItemsForPatients = [
    {
        label: 'Surgeon Brochure for KEYTRUDA',
        url: '/static/pdf/US-KEY-08326.pdf',
        resourceBodyText: 'A resource to help you identify patients eligible for KEYTRUDA in NSCLC, TNBC, RCC, or melanoma, learn about the mechanism of action for KEYTRUDA, and get tips on collaborating with oncologists.',
        image: () => (
            <StaticImage src="../../assets/keytruda-surgeon-brochure.png" placeholder="TRACED_SVG" alt="Download a Surgeon Brochure to Identify Eligible Patients for KEYTRUDA® (pembrolizumab) in NSCLC, TNBC, RCC, and Melanoma" />
        ),
    },
    {
        label: 'KEYTRUDA: End Points Brochure',
        url: '/static/pdf/US-KEY-08267.pdf',
        resourceBodyText: 'Learn more about the end points and study designs used in the clinical trials for KEYTRUDA in NSCLC, TNBC, RCC, and melanoma.',
        image: () => (
            <StaticImage src="../../assets/key-endpoints-brochure.png" placeholder="TRACED_SVG" alt="Download a Surgeon End Points Brochure for KEYTRUDA® (pembrolizumab) in NSCLC, TNBC, RCC, and Melanoma"  />
        ),
    },
    {
        label: 'Signs and Symptoms of Select Immune-Mediated Adverse Reactions (IMARs) and Other Adverse Reactions Brochure',
        url: '/static/pdf/US-KEY-07891.pdf',
        resourceBodyText: 'In this resource, you can review some select signs and symptoms of select IMARs and other adverse reactions that patients should report. The list presented in the brochure is not exhaustive.',
        image: () => (
            <StaticImage src="../../assets/multidisciplinary-care-coordination-resource.png" placeholder="TRACED_SVG" alt="Download a Resource to Review Signs and Symptoms of Select Immune-Mediated Adverse Reactions (IMARs) and Other Adverse Reactions Brochure for KEYTRUDA® (pembrolizumab)" />
        ),
    },
    {
        label: 'Adjuvant Treatment in RCC: Patient Identification Tool',
        url: '/static/pdf/US-JRC-00442.pdf',
        resourceBodyText: 'A resource to help you identify patients eligible to receive KEYTRUDA in the adjuvant setting post surgery, and how that relates to the AJCC Prognostic Stage Groups for RCC.',
        image: () => (
            <StaticImage src="../../assets/adjuvant-rcc-patient-identification-tool.png" placeholder="TRACED_SVG" alt="Download a Guide to Help Identify Eligible Patients to Receive KEYTRUDA® (pembrolizumab) Post-surgery as an Adjuvant Treatment in RCC" />
        ),
        disabled: true
    },
]

const pageReferences = [
    {
        label: "1.",
        text: "Wakelee H, Liberman M, Kato T, et al. Perioperative pembrolizumab for early-stage non-small-cell lung cancer. <i>N Engl J Med.</i> 2023;389(6):491–503. doi:10.1056/NEJMoa2302983"
    },
    {
        label: "2.",
        text: "O’Brien M, Paz-Ares L, Marreaud S, et al. Pembrolizumab versus placebo as adjuvant therapy for completely resected stage IB–IIIA non-small-cell lung cancer (PEARLS/KEYNOTE-091): an interim analysis of a randomised, triple-blind, phase 3 trial. <i>Lancet Oncol.</i> 2022;23(10):1274–1286. doi:10.1016/S1470-2045(22)00518-6"
    },
    {
        label: "3.",
        text: "Schmid P, Cortes J, Dent R, et al. Pembrolizumab or placebo + chemotherapy followed by pembrolizumab or placebo for early-stage triple-negative breast cancer: updated event-free survival results from the phase 3 KEYNOTE-522 study. Slide deck presented at: European Society for Medical Oncology; October 20, 2023; Madrid, Spain."
    },
    {
        label: "4.",
        text: "Choueiri TK, Tomczak P, Park SH, et al. Adjuvant pembrolizumab after nephrectomy in renal-cell carcinoma. <i>N Engl J Med.</i> 2021;385(8):683–694. doi:10.1056/NEJMoa2106391"
    },
    {
        label: "5.",
        text: "Luke JJ, Rutkowski P, Queirolo P, et al. Pembrolizumab versus placebo as adjuvant therapy in completely resected stage IIB or IIC melanoma (KEYNOTE-716): a randomised, double-blind, phase 3 trial. <i>Lancet.</i> 2022;399(10336):1718–1729. doi:10.1016/S0140-6736(22)00562-1"
    },
    {
        label: "6.",
        text: "Eggermont AMM, Blank CU, Mandala M, et al. Adjuvant pembrolizumab versus placebo in resected stage III melanoma. <i>N Engl J Med.</i> 2018;378(19):1789–1801. doi:10.1056/NEJMoa1802357"
    },
    {
        label: "7.",
        text: "Berardi R, Morgese F, Rinaldi S, et al. Benefits and limitations of a multidisciplinary approach in cancer patient management. <em>Cancer Manag Res.</em> 2020;12:9363-9374. doi:10.2147/CMAR.S220976"
    },
    {
        label: "8.",
        text: "Surgical oncologist. City of Hope. Updated April 7, 2023. Accessed July 13, 2023. https://www.cancercenter.com/physician-directory/types-oncologists/surgical-oncologists"
    },
]


const Page = ({ location }) => {
    const [allResourcesDisabled, setAllResourcesDisabled] = useState(false);
    useEffect(() => {
        const disableHandler = (event) => {
            if (event.data.action === 'disableAllResources') {
                setAllResourcesDisabled(true);
            }
        };

        window.addEventListener("message", disableHandler);
        return () => {
            window.removeEventListener("message", disableHandler);
        };
    }, []);


    return (
        <DefaultLayout pageMeta={pageMeta} location={location} jobCode={jobCode} >
            <div data-template-name="template-c">
                {/* Begin Intro Column */}
                <TemplateColumn id="template-c-intro" maxWidth={824}>
                    <PageHeader title="Surgeon Resource Center" />
                    <PageSection 
                        bgColor="cloud" 
                        title="As Part of a Treatment Regimen for Certain Cancers, Consider KEYTRUDA® (pembrolizumab) Together With Surgery in the Neoadjuvant and Adjuvant Settings or Adjuvant Setting" 
                        centerTitle={true}
                    >
                        <AnchorLinks items={anchorLinkModelData}/>
                    </PageSection>
                </TemplateColumn>
                {/* End Intro Column */}

                {/* Begin Main Column */}
                <TemplateColumn id="template-c-main">

                    {/* Selected Earlier Stage Indications */}
                    <PageSection title={anchorLinkModelData[0].label}>
                        <IconDefinitionList 
                            theme="light"
                            data={selectedEarlierStageIndicationData}
                            definitions="NSCLC = non–small cell lung cancer; TNBC = triple-negative breast cancer; RCC = renal cell carcinoma."
                        />
                    </PageSection>

                    {/* Resources for Surgeons */}
                    <PageSection title={anchorLinkModelData[1].label} bgColor="cloud" >
                        <TextBlock>
                            <p>As a surgeon, you play a critical role in developing comprehensive treatment plans for patients. The resources below can help you collaborate with your patient’s medical oncologist and multidisciplinary team (MDT) to determine if your patient is eligible for neoadjuvant and adjuvant therapy or adjuvant therapy only, together with surgery.<sup>7,8</sup></p>
                            <p>Here are important resources for TNBC, RCC, melanoma, and NSCLC:</p>
                        </TextBlock>
                        <ResourceLibrary items={resourceLibraryItemsForPatients} allResourcesDisabled={allResourcesDisabled} />

                        {/* Video section */}
                        <PageSubSection title={anchorLinkModelData[2].label}>
                                <Video 
                                    title="RCC: Which Patients May Be Eligible for Adjuvant Treatment?"
                                    description="Learn about risk of recurrence data for certain patients with RCC post surgery, and which patients may be eligible to receive KEYTRUDA in the adjuvant setting after nephrectomy."
                                    videoId="6343505462112"
                                    definitions="TNBC = triple-negative breast cancer; RCC = renal cell carcinoma; NSCLC = non–small cell lung cancer; AJCC = American Joint Committee on Cancer."
                                />
                        </PageSubSection> 

                    </PageSection>    
                    <PageSection bgColor="charcoal">
                        <PrimaryCallOut theme="dark"
                            title="For Your Patients"
                            content="Patients who have been prescribed KEYTRUDA for an approved indication can access additional support from KEY+YOU, the patient support program for KEYTRUDA. The KEYTRUDA Patient Support Program helps patients by offering educational resources and support information throughout treatment."
                            primaryAction={{ 
                                label: 'Patient Resources', 
                                href: '/resources/key-you-patient-support-program/', 
                            }}
                        />
                    </PageSection>              
                </TemplateColumn>
                {/* End Main Column */}

                <TemplateColumn id="template-e-secondary">

                    <PageSection bgColor="keytrudaGreen">
                        <RelatedContent items={relatedContent}/>
                    </PageSection>

                    <PageSection bgColor="mist">
                        <ReferencesBlock references={pageReferences} />
                    </PageSection>

                </TemplateColumn>
            </div>
        </DefaultLayout>
    );
};

export default Page;
